window.addEventListener('load', function (e) {
    loadFtr();
});

function loadFtr() {
    const jsonID = '/assets/data/footer.json';
    fetch(jsonID)
        .then(response => response.json())
        .then(data => {
            const ftrEl = document.querySelector('#companyInfo');
            let ftrStr = '';
            let cnt = 0;
            ftrStr += '<h2>' + data[0].companyName + '</h2>';
            ftrStr += '<p class="address">';
            data[0].address.forEach(function (key) {
                ftrStr += key + '<br>';
            });
            ftrStr += '</p>';
            ftrStr += '<div class="box">';
            ftrStr += '<p class="outline">';
            data[0].companyInfo.forEach(function (key) {
                ftrStr += '<a class="arrowRight" href="' + key.link + '">' + key.label + '</a>';
            });
            ftrStr += '</p>';
            ftrStr += '<p class="copyright">' + data[0].copyright + '</p>';
            ftrEl.innerHTML = ftrStr;
        })
        .then();
}
