
const toggleBtn = document.querySelector('.toggle__button');
const nav = document.querySelector('.nav');

toggleBtn.addEventListener('click', () => {
    nav.classList.toggle('is-open');
    const waiaria = toggleBtn.getAttribute('aria-expanded');
    if (waiaria === 'false') {
        toggleBtn.setAttribute('aria-expanded', 'true');
    } else {
        toggleBtn.setAttribute('aria-expanded', 'false');
    }
});


const jsonID = '/assets/data/navigation.json';
fetch(jsonID)
    .then(response => response.json())
    .then(data => {
        const navEl = document.querySelector('#navList');
        const ftrNavEl = document.querySelector('#footerNav');
        const ftrCntctBtn = document.querySelector('#contactBtn');
        let navStr = '';
        let ftrCntctStr = '';
        navStr += '<ul class="nav__list">';
        data.forEach(function (key) {
            navStr += '<li class="nav__item ' + key.classLi + '"><a class="' + key.class + '" href="' + key.link + '">' + key.label + '</a>';
            if (key.dropdown !== false) {
                navStr += '<div class="dropdown">';
                key.dropdown.forEach(function (key) {
                    navStr += '<div class="dropdown__item">';
                    navStr += '<a class="' + key.class + '" href="' + key.link + '">' + key.label + '</a>';
                    navStr += '</div>';
                });
                navStr += '</div>';
            }
            navStr += '</li>';
        });
        navStr += '</ul>';
        ftrCntctStr += '<a class="button -colored" href="' + data[3].linkFtr + '">メールで' + data[3].label + '</a>';
        navEl.innerHTML = navStr;
        ftrNavEl.innerHTML = navStr;
        ftrCntctBtn.innerHTML = ftrCntctStr;


        var setNavWidth = function () {
            const body = document.querySelector('body');
            const width = body.clientWidth;
            const bp = 768;

            if (width > bp) {
                if (nav.classList.contains('is-open') === true) {
                    nav.classList.remove('is-open');
                    toggleBtn.setAttribute('aria-expanded', 'false');
                }
            }
        }

        setNavWidth();
        var resize = function () {
            var timeoutID = 0;
            var delay = 200;

            window.addEventListener("resize", function () {
                clearTimeout(timeoutID);
                timeoutID = setTimeout(function () {
                    setNavWidth();
                }, delay);
            }, false);
        };
        resize();

        const navWork = document.querySelector('.navWork');
        const dropdownArea = navWork.parentNode;
        const dropdownMenu = document.querySelector('.item3 .dropdown');

        dropdownArea.addEventListener('mouseover', () => {
            dropdownMenu.classList.add('is-open');
        })
        dropdownArea.addEventListener('mouseout', () => {
            dropdownMenu.classList.remove('is-open');
        })

        const navContact = document.querySelector('.navContact');
        const dropdowContactArea = navContact.parentNode;
        const dropdownContactMenu = document.querySelector('.item4 .dropdown');

        dropdowContactArea.addEventListener('mouseover', () => {
            dropdownContactMenu.classList.add('is-open');
            navContact.classList.add('is-hover');
        })
        dropdowContactArea.addEventListener('mouseout', () => {
            dropdownContactMenu.classList.remove('is-open');
            navContact.classList.remove('is-hover');
        })

        const navContactChatbot = document.querySelector('.navContactChatbot');
        const chatbotPanel = document.querySelector('#chatbot__panel');

        navContactChatbot.addEventListener('click', () => {
            chatbotPanel.classList.remove('is-close');
        })


    })
    .then();

