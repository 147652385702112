window.addEventListener('load', function (e) {
    loadjson();
});

function loadjson() {
    const URL = window.location.href;
    const path = location.pathname;
    const pathArr = path.split('/');
    const dirName = pathArr[pathArr.length - 2];
    const baseCategory = dirName;
    if (baseCategory !== '') {
        const jsonID = '/assets/data/' + baseCategory + '.json';

        fetch(jsonID)
            .then(response => response.json())
            .then(data => {
                const secEl = document.querySelector('#pageContCategoryTop');
                const categoryName = data.category;
                const item = data.item;
                if (secEl) {
                    let secStr = '';
                    item.forEach(function (key) {

                        secStr += '<section class="sectionPtn1 ' + key.id + '"><a href="/' + baseCategory + '/' + key.id + '.html">'
                            + '<div class="box">'
                            + '<div class="sectionPtn1__item1">'
                            + '<h2 class="sectionPtn1__ttl">' + key.subttl + '</h2>'
                            + '</div>'
                            + '</div>'
                            + '</a></section>';
                    });
                    secEl.innerHTML = secStr;
                }

                const secInsideEl = document.querySelector('#workDetail');

                if (secInsideEl) {
                    const filename = URL.match(".+/(.+?)\.[a-z]+([\?#;].*)?$")[1];
                    let secInsideStr = '';
                    item.forEach(function (key) {
                        if (key.id === filename) {

                            secInsideStr += '<section class="detail ' + key.id + '">'
                                + '<div class="detail__item1"><div class="detail__shoulder">' + categoryName + '</div><h1 class="detail__ttl">' + key.subttl + '</h1></div>'
                                + '<div class="detail__item2">'
                                + '<p class="detail__text">' + key.text + '</p>';
                            if (key.clmnDD !== '') {
                                secInsideStr += '<dl class="detail__clmn">'
                                    + '<dt>' + key.clmnDT + '</dt>'
                                    + '<dd>' + key.clmnDD + '</dd>'
                                    + '</dl>';
                            }
                            if (key.pictures !== undefined) {
                                secInsideStr += '<div class="detail__pitures">';
                                key.pictures.forEach(function (key) {
                                    secInsideStr += '<figure><img src="' + key.pic + '" alt="' + key.alt + '">';
                                    if (key.caption !== '') {
                                        secInsideStr += '<figcaption>' + key.caption + '</figcaption>';
                                    }

                                    secInsideStr += '</figure>';
                                })
                                secInsideStr += '</div>';
                            }
                            secInsideStr += '</div>';

                            if (key.itemshow === true) {
                                secInsideStr += '<div class="detail__keywordItems">';
                                key.item.forEach(function (key) {
                                    secInsideStr += '<div class="detail__keywordExp">'
                                        + '<h2>' + key.itemTtl + '</h2>'
                                        + '<blockquote>'
                                        + '<p>' + key.quote[0].text + '</p>'
                                        + '<p class="small">引用元：<cite><a href="' + key.quote[0].link + '" target="_blank">' + key.quote[0].origin + '</a></cite></p>'
                                        + '</blockquote>';
                                    if (key.figure.length > 1) {
                                        key.figure.forEach(function (key) {
                                            secInsideStr += '<figure><img src="' + key.img + '" alt="">'
                                                + '<figcaption>' + key.caption + '</figcaption>'
                                                + '</figure>';
                                        })

                                    }

                                    secInsideStr += '</div>';
                                })
                                secInsideStr += '</div>';
                            }

                            secInsideStr += '</section>';
                        }

                    });

                    secInsideEl.innerHTML = secInsideStr;

                    console.log(filename);
                }

                const localNav = document.querySelector('#localNav');

                if (localNav) {
                    let localNavStr = '';
                    const filename = URL.match(".+/(.+?)\.[a-z]+([\?#;].*)?$")[1];
                    localNavStr += '<ul class="localNav__list ' + filename + '">';
                    item.forEach(function (key) {

                        localNavStr += '<li class="localNav__item"><a href="' + key.id + '.html" class="' + key.id + '">' + key.subttl + '</li>';
                    });
                    localNavStr += '</ul>';
                    localNav.innerHTML = localNavStr;
                }

            })
            .then();
    }

}