const chatbotPanel = document.querySelector('#chatbot__panel');
const chatbotBtn = document.querySelector('.chatbot__btn');
const panelCloseBtn = document.querySelector('.panel__closeBtn');

if (chatbotBtn) {
    chatbotBtn.addEventListener('click', () => {
        chatbotPanel.classList.remove('is-close');
    });
}

if (chatbotPanel) {
    panelCloseBtn.addEventListener('click', () => {
        chatbotPanel.classList.add('is-close');
    });
}


